<script>
export default {
    
}
</script>

<template>
    <footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 text-center">{{ new Date().getFullYear()}} © Groupe EMC</div>
        </div>
    </div>
</footer>
</template>