export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "menuitems.dashboard.text",
        icon: "uil-home-alt",
        link: "/"
    },
    {
        id: 3,
        label: "menuitems.prospects",
        icon: "uil-users-alt",
        link: "/prospects"
    },
    {
        id: 4,
        label: "menuitems.customer",
        icon: "uil-user-circle",
        link: "/customers",
        adminOnly: true
    },
    {
        id: 5,
        label: "menuitems.sales",
        icon: "uil-invoice",
        link: "/sales"
    },
    {
        id: 6,
        label: "menuitems.service",
        icon: "uil-file-alt",
        link: "/services"
    },
    {
        id: 7,
        label: "menuitems.service_categories",
        icon: "uil-folder",
        link: "/service_categories",
        salesRestricted: true
    },
    {
        id: 8,
        label: "menuitems.users",
        icon: "uil-constructor",
        link: "/users",
        adminOnly: true
    },
];

